.logo {
  width: 30px;
}

.navbar {
  background-color: #3088cc;
  padding: 10px 20px;
  font-size: 20px;
  font-weight: 700;
  width: 100vw;/* Use viewport width */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
 
}

.navbar__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar__brand {
  text-decoration: none;
}

.navbar__logo {
  width: 100px;
  height: auto;
}

.navbar__links {
  display: flex;
  align-items: center;
  margin-right: 50px;
}

.navbar__link {
  margin: 0 10px;
  text-decoration: none;
  color: rgb(4, 0, 112);
  padding: 10px;
}

.navbar__link:hover {
  color: white;
}

.navbar__dropdown {
  position: relative;
}

.navbar__dropdown-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 0 10px;
  padding: 0;
  text-decoration: none;
  color: rgb(4, 0, 112);
  font-size: 20px;
  font-weight: bold;
  margin-right: 50px;
}

.navbar__dropdown-btn:hover {
  color: white;
}

.navbar__dropdown-content {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #b4b3b3;
  min-width: 160px;
  border-radius: 3%;
  z-index: 1;
}

.navbar__dropdown-content a {
  display: block;
  padding: 10px;
  text-decoration: none;
  color: #000000;
  font-weight: 400;
}

.navbar__dropdown-content a:hover {
  background-color: rgb(68, 138, 203);
  color: white;
}

.navbar__dropdown:hover .navbar__dropdown-content {
  display: block;
}

.navbar.shrink {
  padding: 5px 20px;
  font-size: 18px;
}

.navbar.shrink .navbar__logo {
  width: 80px;
}

.navbar__menu-toggle {
  display: none;
}

@media (max-width: 768px) {
  .navbar__menu-toggle {
    display: block;
    cursor: pointer;
    margin-right: 10%;
  }

  .navbar__links {
    display: none;
    flex-direction: column;
    background-color: #3088cc;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    padding: 10px;
    width: 100vw;
  }

  .navbar__links.show {
    display: flex;
  }

  .bar {
    width: 25px;
    height: 3px;
    background-color: #ffff;
    margin: 2px;
  }
}
