.container {
  text-align: left;
  margin-left: 5%;
  margin-right: 20%;
  display: flex;
  padding: 5px;
  align-items: center;
  background-color: #ffffff;
  transition: background-color 0.3s ease;
}
.container:hover {
  background-color: #5a9ed2;
  padding: 20px;
  border-radius: 2%;
}

.more-content {
  display: none;
}
.more button {
  background-color: rgb(4, 0, 112);
  border: none;
  color: white;
  border-radius: 10%;
  padding: 10px;
}

.more button:hover {
  background-color: rgb(87, 165, 239);
}
.more-content.show {
  display: block;
  background-color: #5a9ed2;
  padding: 20px;
  border-radius: 5%;
}

.icon {
  font-size: 100px;

}

.graph {
  height: 100px;
 
}

.rompe {
  height: 150px;
  
}
