.App {
  top: 0; /* Align the navbar at the top */
  left: 0; /* Align the navbar to the left */
  right: 0; /* Ensure the navbar stretches across the width */
  text-align: center;
  font-size: 18px;
  overflow-x: hidden;
}
* {
  font-family: "Noto Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: <weight>;
  font-style: normal;
  font-variation-settings: "wdth" 100;
}

.header img {
  width: 100vw; /* Use viewport width */
  top: 0;
  left: 0;
  right: 0;
  margin-top: -40px;
}

h2 {
  color: rgb(4, 0, 112);
  text-align: left;
  margin-left: 20px;
  font-size: 40px;
}

.servicios {
  margin-bottom: 30px;
}

html {
  scroll-behavior: smooth;
}

body {
  padding-top: 70px;
}

@media (max-width: 768px) {
  .App {
    width: 100%;
    display: flexbox;
    font-size: small;
  }

  .header img {
    width: 100%; /* Use viewport width */
  }

  body {
    width: 100%;
  }
}
