@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.Hero {
  display: flex; /* Use flexbox to align items */
  justify-content: space-between; /* Space items evenly */
  align-items: center; /* Center items vertically */
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
}

.info {
  flex: 1; /* Take remaining space */
  text-align: left;
  margin-left: 20px;
  align-self: flex-start;
  margin-right: 10%;
  animation: slideInFromLeft 1s ease-out forwards;
}

.info a {
  color: blue;
}

.info a:hover {
  background-color: rgb(4, 0, 112);
  color: white;
  padding: 2px;
}

.info h2 {
  color: rgb(4, 0, 112);
  margin-left:-10px;
}
.Hero img {
  max-height: 300px;
  align-self: flex-start;
}

@media (max-width: 768px) {
  /* Hide image on screens smaller than 768px width */
  .Hero img {
    display: none;
  }
}

/* carousel.css */
.carousel {
  position: relative;
}

.carousel-item {
  display: none;
  position: relative;
  transition: opacity 0.6s ease-in-out;
}

.carousel-item.active {
  display: block;
}

.carousel-item img {
  width: 100%;
  height: auto;
}

.carousel-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  text-align: center;
}

.carousel-caption h3 {
  margin-top: 0;
}

/* You can add additional styling for carousel indicators, controls, etc. as needed */
