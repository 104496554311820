.Contact {
  text-align: center;
  background-color: #3088cc;
  color: white;
  padding: 10px;
  width: 100vw;
}

.contact-details {
  display: flex;
  justify-content: space-between;
}

.lista {
  width: 50%;
  margin-left: 10%;
}

.location {
  width: 50%;
  margin-right: 15%;
  
}

@media (max-width: 650px) {
  .maps {
    width: 80%;
  }
}
